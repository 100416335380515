import { err2s } from '../../utils/common';
import { getTimeViaAPI, logClock } from './shared';
import { type IClock, type Ms } from './types';

export class DummyClock implements IClock {
  async sync(): Promise<void> {
    return;
  }
  now(): number {
    return Date.now();
  }
}

type SyncResult = {
  startMs: Ms;
  endMs: Ms;
  serverTimestampMs: Ms;
  clientToServerMs: Ms;
  serverToClientMs: Ms;
  rttMs: Ms;
};

export class DefaultClock implements IClock {
  private _driftMs = 0;
  constructor(private getTime = getTimeViaAPI, private log = logClock) {
    this.now = this.now.bind(this);
  }
  async sync(n = 3): Promise<void> {
    if (n <= 0)
      throw new Error('invalid parameters, n is not a positive number.');
    try {
      const promises = [];
      for (let i = 0; i < n; i++) {
        promises.push(
          new Promise<SyncResult>(async (resolve, reject) => {
            try {
              const startMs = Date.now();
              const serverTimestampMs = await this.getTime();
              const endMs = Date.now();
              const result: SyncResult = {
                startMs,
                endMs,
                serverTimestampMs,
                clientToServerMs: serverTimestampMs - startMs,
                serverToClientMs: endMs - serverTimestampMs,
                rttMs: endMs - startMs,
              };
              resolve(result);
            } catch (error) {
              reject(error);
            }
          })
        );
      }
      const results = await Promise.all(promises);
      this.log.info(`sync results`, { results });
      this._driftMs = Math.round(
        results.reduce(
          // The clientToServerMs includes both one-way RTT and the potential drift.
          // We assume the rttMs / 2 can represent the one-way RTT, although it's not true.
          // We actually can't tell the real value if the client clock can not be trusted.
          (prev, curr) => prev + curr.clientToServerMs - curr.rttMs / 2,
          0
        ) / n
      );
      this.log.info('clock synced', { driftMs: this._driftMs });
    } catch (error) {
      this.log.error('clock sync failed', err2s(error));
    }
  }

  now(withDrift = true): number {
    const ms = Date.now();
    return withDrift ? ms + this._driftMs : ms;
  }

  get driftMs(): number {
    return this._driftMs;
  }
}
