import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import { DefaultClock } from './clock';
import { type IClock } from './types';

class ClockStore {
  private clock?: IClock;
  public init(clock: IClock): void {
    this.clock = clock;
  }
  public instance(): IClock {
    if (!this.clock) throw new Error('clock is not inited');
    return this.clock;
  }
}

const store = new ClockStore();

// export for non React code
export const Clock: {
  instance(): IClock;
} = store;

type ClockContext = {
  clock: IClock;
};

const Context = createContext<ClockContext | null>(null);

function useClockContext(): ClockContext {
  const ctx = useContext(Context);
  if (!ctx) throw new Error('ClockContext is not in the tree!');
  return ctx;
}

export function useClock(): IClock {
  return useClockContext().clock;
}

export function ClockProvider(props: {
  clock?: IClock;
  children?: ReactNode;
}): JSX.Element | null {
  const ctxValue = useMemo(() => {
    const instance = props.clock ? props.clock : new DefaultClock();
    store.init(instance);
    return { clock: instance };
  }, [props.clock]);
  const clockSyncingEnabled = useInstance(() =>
    getFeatureQueryParam('clock-syncing')
  );
  const [inited, setInited] = useState(false);

  useEffect(() => {
    async function init() {
      if (clockSyncingEnabled) await ctxValue.clock.sync();
      setInited(true);
    }
    init();
  }, [ctxValue.clock, clockSyncingEnabled]);

  if (!inited) return null;

  return <Context.Provider value={ctxValue}>{props.children}</Context.Provider>;
}
