import logger from '../../logger/logger';
import { apiService } from '../../services/api-service';
import { type Ms } from './types';

export const logClock = logger.scoped('clock');

export async function getTimeViaAPI(): Promise<Ms> {
  const resp = await apiService.misc.ping();
  return resp.data.timestampMs;
}
